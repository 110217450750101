var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"operations"},[_c('v-overlay',{attrs:{"value":_vm.loading}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"50"}})],1),[_c('v-row',[_c('v-col',{staticClass:"mt-3",attrs:{"cols":"2"}},[_c('v-menu',{ref:"datesMenu",attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticClass:"textField",attrs:{"label":"Период","prepend-icon":"mdi-calendar-month","readonly":"","outlined":""},model:{value:(_vm.datesStr),callback:function ($$v) {_vm.datesStr=$$v},expression:"datesStr"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.datesMenu),callback:function ($$v) {_vm.datesMenu=$$v},expression:"datesMenu"}},[(_vm.datesMenu)?_c('v-date-picker',{attrs:{"range":"","no-title":"","min":"2019-01-01","type":"month","locale":"ru-Latn","full-width":""},on:{"click:month":_vm.saveMonth},model:{value:(_vm.dates),callback:function ($$v) {_vm.dates=$$v},expression:"dates"}}):_vm._e()],1)],1),_c('v-col',{staticClass:"mt-3 btnLeft pr-1",attrs:{"cols":"10"}},[_c('v-btn',{staticClass:"px-1 mx-0",attrs:{"dense":"","small":"","color":"primary"},on:{"click":_vm.downloadXls}},[_c('span',[_vm._v("Скачать")]),_c('v-icon',[_vm._v("mdi-file-excel")])],1)],1)],1)],(_vm.isMobile)?_c('div',{staticClass:"checkBoxes my-n5"},[(_vm.isMobile)?_c('v-checkbox',{staticClass:"pa-0 ma-0",attrs:{"label":"Раскрывать ячейки по одной"},model:{value:(_vm.enableSingleExpand),callback:function ($$v) {_vm.enableSingleExpand=$$v},expression:"enableSingleExpand"}}):_vm._e()],1):_vm._e(),_c('h1',{staticClass:"subheading grey--text"},[_vm._v("Отчет")]),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Поиск","single-line":"","hide-details":""},model:{value:(_vm.totalsTable.search),callback:function ($$v) {_vm.$set(_vm.totalsTable, "search", $$v)},expression:"totalsTable.search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","hide-default-footer":false,"headers":_vm.headersReport,"items":_vm.carOperations.cars,"items-per-page":10,"search":_vm.totalsTable.search,"single-expand":_vm.enableSingleExpand,"mobile-breakpoint":"0"},on:{"click:row":_vm.expandOperations},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-simple-table',{staticClass:"mb-3 grey darken-3",attrs:{"dense":""}},[_c('tbody',[_c('tr',[_c('td',[_vm._v(" Доля ")]),_c('td',[_vm._v(" "+_vm._s(item.chunk)+" ")])]),_c('tr',[_c('td',[_vm._v(" Выручка ")]),_c('td',[_vm._v(" "+_vm._s(item.proceeds.toLocaleString('en-US').replaceAll(',', ' '))+" ")])]),_c('tr',[_c('td',[_vm._v(" Расходы ")]),_c('td',[_vm._v(" "+_vm._s(item.consumption .toLocaleString('en-US') .replaceAll(',', ' '))+" ")])]),_c('tr',[_c('td',[_vm._v(" Комиссия УК ")]),_c('td',[_vm._v(" "+_vm._s(item.comission.toLocaleString('en-US').replaceAll(',', ' '))+" ")])]),_c('tr',[_c('td',[_vm._v(" Доход авто ")]),_c('td',[_vm._v(" "+_vm._s(item.clearProfit .toLocaleString('en-US') .replaceAll(',', ' '))+" ")])])])])],1)]}},{key:"item.ownerProfit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.ownerProfit.toLocaleString('en-US').replaceAll(',', '\u00A0'))+" ")]}},{key:"item.clearProfit",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.clearProfit.toLocaleString('en-US').replaceAll(',', '\u00A0'))+" ")]}}])},[(!_vm.loading && _vm.carOperations.cars)?_c('template',{slot:"body.append"},[_c('tr',{staticClass:"primary--text"},[_c('th',{attrs:{"colspan":_vm.footerColspan}},[_vm._v("Все авто")]),_c('th',{staticStyle:{"text-align":"center"}},[_vm._v(_vm._s(_vm.sumField('ownerProfit')))])])]):_vm._e()],2),_c('h1',{staticClass:"subheading grey--text mt-4"},[_vm._v("Реестр операций")]),_c('div',{staticClass:"checkBoxes mb-4"},[_c('v-checkbox',{staticClass:"pa-0 ma-0 mb-n5",attrs:{"label":"Выделить цветом операции"},model:{value:(_vm.enableColors),callback:function ($$v) {_vm.enableColors=$$v},expression:"enableColors"}})],1),_c('v-select',{staticClass:"mb-n5",attrs:{"dense":"","items":_vm.carNameForOperations,"label":_vm.filtersOperations.cars.length ? 'Выбранные авто' : 'Все авто',"outlined":"","multiple":""},model:{value:(_vm.filtersOperations.cars),callback:function ($$v) {_vm.$set(_vm.filtersOperations, "cars", $$v)},expression:"filtersOperations.cars"}}),_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Поиск","single-line":"","hide-details":""},model:{value:(_vm.register.search),callback:function ($$v) {_vm.$set(_vm.register, "search", $$v)},expression:"register.search"}}),_c('v-data-table',{staticClass:"elevation-1",attrs:{"dense":"","headers":_vm.headersReestr,"items":_vm.carOperations.carsRefills,"item-class":_vm.itemRowBackground,"items-per-page":10,"search":_vm.register.search,"single-expand":_vm.enableSingleExpand,"mobile-breakpoint":"0"},on:{"click:row":_vm.expandOperations},scopedSlots:_vm._u([{key:"top",fn:function(ref){
var pagination = ref.pagination;
var options = ref.options;
var updateOptions = ref.updateOptions;
return [_c('v-data-footer',{staticClass:"noItemsPerPage",attrs:{"pagination":pagination,"options":options,"items-per-page-text":"$vuetify.dataTable.itemsPerPageText","disable-items-per-page":""},on:{"update:options":updateOptions}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('v-simple-table',{staticClass:"mb-3 grey darken-3",attrs:{"dense":""}},[_c('tbody',[_c('tr',[_c('td',[_vm._v(" Id ")]),_c('td',[_vm._v(" "+_vm._s(item.id)+" ")])]),_c('tr',[_c('td',[_vm._v(" Контрагент ")]),_c('td',[_vm._v(" "+_vm._s(item.counterparty)+" ")])]),_c('tr',[_c('td',[_vm._v(" Сумма ")]),_c('td',[_vm._v(" "+_vm._s(item.sum.toLocaleString('en-US').replaceAll(',', ' '))+" ")])]),_c('tr',[_c('td',[_vm._v(" Тип ")]),_c('td',[_vm._v(" "+_vm._s(item.type)+" ")])]),_c('tr',[_c('td',[_vm._v(" Комментарий ")]),_c('td',[_vm._v(" "+_vm._s(item.comment)+" ")])])])])],1)]}},{key:"item.dateTime",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.dateFormat(item.dateTime))+" ")]}},{key:"item.article",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.textFormat(item.article))+" ")]}}])})],2)}
var staticRenderFns = []

export { render, staticRenderFns }